<template>
  <div :class="colSize" class="row self-start q-mt-md q-py-sm">
    <div class="col-12 q-px-sm q-pt-md text-h5">Contactgegevens</div>
    <span
      class="col-9 q-px-sm text-caption text-grey-9"
      v-if="view == 'retention'"
    >
      Onderstaande gegevens worden alleen aangepast voor het versturen van de
      aanbieding en worden niet doorgegeven aan de adverteerder.
    </span>
    <span class="col-3" v-if="view == 'retention'"></span>
    <div class="col-6 q-px-sm q-my-sm">
      <q-input
        :readonly="readonly"
        dense
        outlined
        :modelValue="phone_number"
        mask="##########"
        @change="phone_number = $event"
        label="Telefoonnummer"
        :rules="validate('phone')"
        bottom-slots
      />
    </div>
    <div class="col-6 q-px-sm q-my-sm">
      <q-input
        :readonly="readonly"
        dense
        outlined
        :modelValue="email"
        label="Email"
        @change="email = $event"
        :rules="emailValidRule"
      />
      <q-tooltip class="bg-primary text-body2" :offset="[50, -95]">
        Dit wordt gebruikt voor het versturen van de aanbieding en de eventuele
        bevestiging na acceptatie.
      </q-tooltip>
    </div>
  </div>
  <div class="col-12 justify-end row q-pt-sm q-py-sm">
    <div
      v-if="settings?.newsletter?.enabled ?? false"
      :class="colSizeSmall"
      class="row q-px-sm"
    >
      <div class="col-8">Wilt u zich inschrijven voor de nieuwsbrief?</div>
      <q-toggle
        dense
        :disable="readonly"
        class="text-weight-bold q-pa-none"
        v-model="newsletter"
        color="green"
        false-value="Nee"
        true-value="Ja"
        icon="feed"
        size="xl"
        :label="newsletter == 'Ja' ? 'Ja' : 'Nee'"
      />
    </div>
    <div
      v-if="settings?.digital_invoice?.enabled ?? false"
      :class="colSizeSmall"
      class="row q-px-sm"
    >
      <div class="col-8">
        Wilt u via dit e-mailadres uw facturen digitaal ontvangen?
      </div>
      <q-toggle
        dense
        :disable="readonly"
        class="text-weight-bold"
        v-model="digital_invoice"
        color="green"
        false-value="Nee"
        true-value="Ja"
        icon="email"
        size="xl"
        :label="digital_invoice == 'Ja' ? 'Ja' : 'Nee'"
      />
    </div>
  </div>
  <div class="col-12 justify-end row q-py-sm q-mt-md">
    <div
      v-if="settings?.email_validation?.enabled ?? true"
      :class="colSizeSmall"
      class="row q-px-sm"
      style="height: 40px"
    >
      <q-btn
        no-caps
        dense
        outlined
        class="text-center text-weight-bold"
        :disable="email_valid == true || readonly"
        @click="validateEmail"
        label="Valideer Email"
        color="primary"
        style="height: 100%; width: 100%"
        :loading="loading.email ?? false"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useQuasar } from "quasar";
import { useStore } from "vuex";
import { validate, validator } from "../../plugins/validation.js";

//* Props
// eslint-disable-next-line no-undef
const props = defineProps({
  settings: Object,
});

//* VueUse
const $q = useQuasar();
const $store = useStore();

//* Refs
const emailValidRule = [
  (val) =>
    // Shamelessly lifted from Scott Gonzalez via the Bassistance Validation plugin http://projects.scottsplayground.com/email_address_validation/
    // eslint-disable-next-line
        (val !== null && val !== "" && (/^(((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)?$/i).test(val)) ||
    "Vul een volledig emailadres in",
];

//* Computed
const colSize = computed(() => ($q.screen.lt.md ? "col-12" : "col-6"));
const colSizeSmall = computed(() => ($q.screen.lt.md ? "col-6" : "col-3"));
const email_valid = computed(() => $store.getters.emailValid);
const view = computed(() => $store.getters.view);
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);

const loading = computed({
  get() {
    return $store.getters.loading ?? false;
  },
  set(newVal) {
    $store.dispatch("setLoading", {
      key: newVal[0],
      value: newVal[1],
    });
  },
});

const phone_number = computed({
  get() {
    return $store.getters.record?.Telefoon ?? "";
  },
  set(newVal) {
    $store.dispatch("updateRecord", {
      key: "Telefoon",
      value: newVal,
      validated: validator("phone", newVal),
    });
  },
});

const email = computed({
  get() {
    return $store.getters.record?.Email ?? "";
  },
  set(newVal) {
    $store.dispatch("setValidEmail", null);
    $store.dispatch("updateRecord", {
      key: "Email",
      value: newVal,
      validated: validator(emailValidRule, newVal),
    });
  },
});

const digital_invoice = computed({
  get() {
    return $store.getters.record?.digitale_factuur ?? "Ja";
  },
  set(newVal) {
    $store.dispatch("updateRecord", {
      key: "digitale_factuur",
      value: newVal,
    });

    $store.dispatch("setProjectSpecificValue", {
      key: "preferred_channel",
      value: newVal == "Ja" ? "email" : "letter",
    });
  },
});

const newsletter = computed({
  get() {
    return $store.getters.projectSpecific?.newsletter ?? "Nee";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "newsletter",
      value: newVal == "Ja" ? "Ja" : "Nee",
    });
  },
});

//* Methods
function validateEmail() {
  loading.value = ["email", true];
  $store.dispatch("getValidEmail", {
    email: email.value,
    CTPID: $store.getters.steamVelden?.RecordID ?? "",
    Recordid: $store.getters.record?.id ?? "",
  });
}

//* Conditions
if (!$store.getters.projectSpecific?.newsletter) {
  newsletter.value = props?.settings?.newsletter?.default ?? "Nee";
}
</script>
